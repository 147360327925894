import hrmpTypes from './hrmp.js';
import { runtime } from './runtime.js';
import slotTypes from './slots.js';
const proposeTypes = {
    ParachainProposal: {
        proposer: 'AccountId',
        genesisHead: 'HeadData',
        validators: 'Vec<ValidatorId>',
        name: 'Bytes',
        balance: 'Balance'
    },
    RegisteredParachainInfo: {
        validators: 'Vec<ValidatorId>',
        proposer: 'AccountId'
    }
};
const cumulusTypes = {
    ServiceQuality: {
        _enum: ['Ordered', 'Fast']
    }
};
const disputeTypes = {
    DisputeLocation: {
        _enum: ['Local', 'Remote']
    },
    DisputeResult: {
        _enum: ['Valid', 'Invalid']
    },
    DisputeState: {
        validatorsFor: 'BitVec',
        validatorsAgainst: 'BitVec',
        start: 'BlockNumber',
        concludedAt: 'Option<BlockNumber>'
    },
    DisputeStatement: {
        _enum: {
            Valid: 'ValidDisputeStatementKind',
            Invalid: 'InvalidDisputeStatementKind'
        }
    },
    DisputeStatementSet: {
        candidateHash: 'CandidateHash',
        session: 'SessionIndex',
        statements: 'Vec<(DisputeStatement, ParaValidatorIndex, ValidatorSignature)>'
    },
    ExecutorParam: {
        _enum: {
            Phantom: 'Null', // index starts at 1... empty slot at 0
            MaxMemoryPages: 'u32',
            StackLogicalMax: 'u32',
            StackNativeMax: 'u32',
            PrecheckingMaxMemory: 'u64',
            PvfPrepTimeout: '(PvfPrepTimeoutKind, u64)',
            PvfExecTimeout: '(PvfExecTimeoutKind, u64)'
        }
    },
    ExecutorParamsHash: 'Hash',
    ExecutorParams: 'Vec<ExecutorParam>',
    ExplicitDisputeStatement: {
        valid: 'bool',
        candidateHash: 'CandidateHash',
        session: 'SessionIndex'
    },
    InvalidDisputeStatementKind: {
        _enum: ['Explicit']
    },
    MultiDisputeStatementSet: 'Vec<DisputeStatementSet>',
    PvfExecTimeoutKind: {
        _enum: ['Backing', 'Approval']
    },
    PvfPrepTimeoutKind: {
        _enum: ['Precheck', 'Lenient']
    },
    ValidDisputeStatementKind: {
        _enum: {
            Explicit: 'Null',
            BackingSeconded: 'Hash',
            BackingValid: 'Hash',
            ApprovalChecking: 'Null'
        }
    }
};
export default {
    rpc: {},
    runtime,
    types: {
        ...cumulusTypes,
        ...disputeTypes,
        ...hrmpTypes,
        ...proposeTypes,
        ...slotTypes,
        AbridgedCandidateReceipt: {
            parachainIndex: 'ParaId',
            relayParent: 'Hash',
            headData: 'HeadData',
            collator: 'CollatorId',
            signature: 'CollatorSignature',
            povBlockHash: 'Hash',
            commitments: 'CandidateCommitments'
        },
        AbridgedHostConfiguration: {
            maxCodeSize: 'u32',
            maxHeadDataSize: 'u32',
            maxUpwardQueueCount: 'u32',
            maxUpwardQueueSize: 'u32',
            maxUpwardMessageSize: 'u32',
            maxUpwardMessageNumPerCandidate: 'u32',
            hrmpMaxMessageNumPerCandidate: 'u32',
            validationUpgradeFrequency: 'BlockNumber',
            validationUpgradeDelay: 'BlockNumber'
        },
        AbridgedHrmpChannel: {
            maxCapacity: 'u32',
            maxTotalSize: 'u32',
            maxMessageSize: 'u32',
            msgCount: 'u32',
            totalSize: 'u32',
            mqcHead: 'Option<Hash>'
        },
        ApprovalVotingParams: {
            maxApprovalCoalesceCount: 'u32'
        },
        AssignmentId: 'AccountId',
        AssignmentKind: {
            _enum: {
                Parachain: 'Null',
                Parathread: '(CollatorId, u32)'
            }
        },
        AsyncBackingParams: {
            maxCandidateDepth: 'u32',
            allowedAncestryLen: 'u32'
        },
        AttestedCandidate: {
            candidate: 'AbridgedCandidateReceipt',
            validityVotes: 'Vec<ValidityAttestation>',
            validatorIndices: 'BitVec'
        },
        AuthorityDiscoveryId: 'AccountId',
        AvailabilityBitfield: 'BitVec',
        AvailabilityBitfieldRecord: {
            bitfield: 'AvailabilityBitfield',
            submittedTt: 'BlockNumber'
        },
        BackedCandidate: {
            candidate: 'CommittedCandidateReceipt',
            validityVotes: 'Vec<ValidityAttestation>',
            validatorIndices: 'BitVec'
        },
        BackingState: {
            constraints: 'Constraints',
            pendingAvailability: 'Vec<CandidatePendingAvailability>'
        },
        BufferedSessionChange: {
            applyAt: 'BlockNumber',
            validators: 'Vec<ValidatorId>',
            queued: 'Vec<ValidatorId>',
            sessionIndex: 'SessionIndex'
        },
        CandidateCommitments: {
            upwardMessages: 'Vec<UpwardMessage>',
            horizontalMessages: 'Vec<OutboundHrmpMessage>',
            newValidationCode: 'Option<ValidationCode>',
            headData: 'HeadData',
            processedDownwardMessages: 'u32',
            hrmpWatermark: 'BlockNumber'
        },
        CandidateDescriptor: {
            paraId: 'ParaId',
            relayParent: 'RelayChainHash',
            collatorId: 'CollatorId',
            persistedValidationDataHash: 'Hash',
            povHash: 'Hash',
            erasureRoot: 'Hash',
            signature: 'CollatorSignature',
            paraHead: 'Hash',
            validationCodeHash: 'ValidationCodeHash'
        },
        CandidateEvent: {
            _enum: {
                CandidateBacked: '(CandidateReceipt, HeadData, CoreIndex, GroupIndex)',
                CandidateIncluded: '(CandidateReceipt, HeadData, CoreIndex, GroupIndex)',
                CandidateTimedOut: '(CandidateReceipt, HeadData, CoreIndex)'
            }
        },
        CandidateHash: 'Hash',
        CandidateInfo: {
            who: 'AccountId',
            deposit: 'Balance'
        },
        CandidatePendingAvailability: {
            core: 'CoreIndex',
            hash: 'CandidateHash',
            descriptor: 'CandidateDescriptor',
            availabilityVotes: 'BitVec',
            backers: 'BitVec',
            relayParentNumber: 'BlockNumber',
            backedInNumber: 'BlockNumber',
            backingGroup: 'GroupIndex'
        },
        CandidateReceipt: {
            descriptor: 'CandidateDescriptor',
            commitmentsHash: 'Hash'
        },
        GlobalValidationData: {
            maxCodeSize: 'u32',
            maxHeadDataSize: 'u32',
            blockNumber: 'BlockNumber'
        },
        CollatorId: 'H256',
        CollatorSignature: 'Signature',
        CommittedCandidateReceipt: {
            descriptor: 'CandidateDescriptor',
            commitments: 'CandidateCommitments'
        },
        Constraints: {
            minRelayParentNumber: 'BlockNumber',
            maxPovSize: 'u32',
            maxCodeSize: 'u32',
            umpRemaining: 'u32',
            umpRemainingBytes: 'u32',
            maxUmpNumPerCandidate: 'u32',
            dmpRemainingMessages: 'Vec<BlockNumber>',
            hrmpInbound: 'InboundHrmpLimitations',
            hrmpChannelsOut: 'HashMap<ParaId, OutboundHrmpChannelLimitations>',
            maxHrmpNumPerCandidate: 'u32',
            requiredParent: 'HeadData',
            validationCodeHash: 'ValidationCodeHash',
            upgradeRestriction: 'Option<UpgradeRestriction>',
            futureValidationCode: 'Option<(BlockNumber, ValidationCodeHash)>'
        },
        CoreAssignment: {
            core: 'CoreIndex',
            paraId: 'ParaId',
            kind: 'AssignmentKind',
            groupIdx: 'GroupIndex'
        },
        CoreIndex: 'u32',
        CoreOccupied: {
            _enum: {
                Parathread: 'ParathreadEntry',
                Parachain: 'Null'
            }
        },
        CoreState: {
            _enum: {
                Occupied: 'OccupiedCore',
                Scheduled: 'ScheduledCore',
                Free: 'Null'
            }
        },
        DisputeProof: {
            timeSlot: 'DisputesTimeSlot',
            kind: 'SlashingOffenceKind',
            validatorIndex: 'ValidatorIndex',
            validatorId: 'ValidatorId'
        },
        DisputesTimeSlot: {
            sessionIndex: 'SessionIndex',
            candidateHash: 'CandidateHash'
        },
        DoubleVoteReport: {
            identity: 'ValidatorId',
            first: '(Statement, ValidatorSignature)',
            second: '(Statement, ValidatorSignature)',
            proof: 'MembershipProof',
            signingContext: 'SigningContext'
        },
        DownwardMessage: 'Bytes',
        GroupIndex: 'u32',
        GroupRotationInfo: {
            sessionStartBlock: 'BlockNumber',
            groupRotationFrequency: 'BlockNumber',
            now: 'BlockNumber'
        },
        GlobalValidationSchedule: {
            maxCodeSize: 'u32',
            maxHeadDataSize: 'u32',
            blockNumber: 'BlockNumber'
        },
        HeadData: 'Bytes',
        HostConfiguration: {
            maxCodeSize: 'u32',
            maxHeadDataSize: 'u32',
            maxUpwardQueueCount: 'u32',
            maxUpwardQueueSize: 'u32',
            maxUpwardMessageSize: 'u32',
            maxUpwardMessageNumPerCandidate: 'u32',
            hrmpMaxMessageNumPerCandidate: 'u32',
            validationUpgradeFrequency: 'BlockNumber',
            validationUpgradeDelay: 'BlockNumber',
            maxPovSize: 'u32',
            maxDownwardMessageSize: 'u32',
            preferredDispatchableUpwardMessagesStepWeight: 'Weight',
            hrmpMaxParachainOutboundChannels: 'u32',
            hrmpMaxParathreadOutboundChannels: 'u32',
            hrmpOpenRequestTtl: 'u32',
            hrmpSenderDeposit: 'Balance',
            hrmpRecipientDeposit: 'Balance',
            hrmpChannelMaxCapacity: 'u32',
            hrmpChannelMaxTotalSize: 'u32',
            hrmpMaxParachainInboundChannels: 'u32',
            hrmpMaxParathreadInboundChannels: 'u32',
            hrmpChannelMaxMessageSize: 'u32',
            codeRetentionPeriod: 'BlockNumber',
            parathreadCores: 'u32',
            parathreadRetries: 'u32',
            groupRotationFrequency: 'BlockNumber',
            chainAvailabilityPeriod: 'BlockNumber',
            threadAvailabilityPeriod: 'BlockNumber',
            schedulingLookahead: 'u32',
            maxValidatorsPerCore: 'Option<u32>',
            maxValidators: 'Option<u32>',
            disputePeriod: 'SessionIndex',
            disputePostConclusionAcceptancePeriod: 'BlockNumber',
            disputeMaxSpamSlots: 'u32',
            disputeConclusionByTimeOutPeriod: 'BlockNumber',
            noShowSlots: 'u32',
            nDelayTranches: 'u32',
            zerothDelayTrancheWidth: 'u32',
            neededApprovals: 'u32',
            relayVrfModuloSamples: 'u32'
        },
        InboundDownwardMessage: {
            pubSentAt: 'BlockNumber',
            pubMsg: 'DownwardMessage'
        },
        InboundHrmpMessage: {
            sentAt: 'BlockNumber',
            data: 'Bytes'
        },
        InboundHrmpLimitations: {
            validWatermarks: 'Vec<BlockNumber>'
        },
        InboundHrmpMessages: 'Vec<InboundHrmpMessage>',
        LocalValidationData: {
            parentHead: 'HeadData',
            balance: 'Balance',
            codeUpgradeAllowed: 'Option<BlockNumber>'
        },
        MessageIngestionType: {
            downwardMessages: 'Vec<InboundDownwardMessage>',
            horizontalMessages: 'BTreeMap<ParaId, InboundHrmpMessages>'
        },
        MessageQueueChain: 'RelayChainHash',
        NodeFeatures: 'BitVec',
        OccupiedCore: {
            nextUpOnAvailable: 'Option<ScheduledCore>',
            occupiedSince: 'BlockNumber',
            timeOutAt: 'BlockNumber',
            nextUpOnTimeOut: 'Option<ScheduledCore>',
            availability: 'BitVec',
            groupResponsible: 'GroupIndex',
            candidateHash: 'CandidateHash',
            candidateDescriptor: 'CandidateDescriptor'
        },
        OccupiedCoreAssumption: {
            _enum: ['Included,', 'TimedOut', 'Free']
        },
        OutboundHrmpChannelLimitations: {
            bytesRemaining: 'u32',
            messagesRemaining: 'u32'
        },
        OutboundHrmpMessage: {
            recipient: 'u32',
            data: 'Bytes'
        },
        PendingSlashes: {
            _alias: {
                slashKeys: 'keys'
            },
            slashKeys: 'BTreeMap<ValidatorIndex, ValidatorId>',
            kind: 'SlashingOffenceKind'
        },
        ParachainDispatchOrigin: {
            _enum: ['Signed', 'Parachain', 'Root']
        },
        ParachainInherentData: {
            validationData: 'PersistedValidationData',
            relayChainState: 'StorageProof',
            downwardMessages: 'Vec<InboundDownwardMessage>',
            horizontalMessages: 'BTreeMap<ParaId, VecInboundHrmpMessage>'
        },
        ParachainsInherentData: {
            bitfields: 'SignedAvailabilityBitfields',
            backedCandidates: 'Vec<BackedCandidate>',
            disputes: 'MultiDisputeStatementSet',
            parentHeader: 'Header'
        },
        ParaGenesisArgs: {
            genesisHead: 'Bytes',
            validationCode: 'Bytes',
            parachain: 'bool'
        },
        ParaId: 'u32',
        ParaInfo: {
            manager: 'AccountId',
            deposit: 'Balance',
            locked: 'bool'
        },
        ParaLifecycle: {
            _enum: ['Onboarding', 'Parathread', 'Parachain', 'UpgradingToParachain', 'DowngradingToParathread', 'OutgoingParathread', 'OutgoingParachain']
        },
        ParaPastCodeMeta: {
            upgradeTimes: 'Vec<ReplacementTimes>',
            lastPruned: 'Option<BlockNumber>'
        },
        ParaScheduling: {
            _enum: ['Always', 'Dynamic']
        },
        ParathreadClaim: '(ParaId, CollatorId)',
        ParathreadClaimQueue: {
            queue: 'Vec<QueuedParathread>',
            nextCoreOffset: 'u32'
        },
        ParathreadEntry: {
            claim: 'ParathreadClaim',
            retries: 'u32'
        },
        ParaValidatorIndex: 'u32',
        PersistedValidationData: {
            parentHead: 'HeadData',
            relayParentNumber: 'RelayChainBlockNumber',
            relayParentStorageRoot: 'Hash',
            maxPovSize: 'u32'
        },
        PvfCheckStatement: {
            accept: 'bool',
            subject: 'ValidationCodeHash',
            sessionIndex: 'SessionIndex',
            validatorIndex: 'ParaValidatorIndex'
        },
        QueuedParathread: {
            claim: 'ParathreadEntry',
            coreOffset: 'u32'
        },
        RelayBlockNumber: 'u32',
        RelayChainBlockNumber: 'RelayBlockNumber',
        RelayHash: 'Hash',
        RelayChainHash: 'RelayHash',
        Remark: '[u8; 32]',
        ReplacementTimes: {
            expectedAt: 'BlockNumber',
            activatedAt: 'BlockNumber'
        },
        Retriable: {
            _enum: {
                Never: 'Null',
                WithRetries: 'u32'
            }
        },
        ScheduledCore: {
            paraId: 'ParaId',
            collator: 'Option<CollatorId>'
        },
        Scheduling: {
            _enum: ['Always', 'Dynamic']
        },
        ScrapedOnChainVotes: {
            session: 'SessionIndex',
            backingValidatorsPerCandidate: 'Vec<(CandidateReceipt, Vec<(ParaValidatorIndex, ValidityAttestation)>)>',
            disputes: 'MultiDisputeStatementSet'
        },
        SessionInfo: {
            activeValidatorIndices: 'Vec<ParaValidatorIndex>',
            randomSeed: '[u8; 32]',
            disputePeriod: 'SessionIndex',
            validators: 'Vec<ValidatorId>',
            discoveryKeys: 'Vec<AuthorityDiscoveryId>',
            assignmentKeys: 'Vec<AssignmentId>',
            validatorGroups: 'Vec<Vec<ValidatorIndex>>',
            nCores: 'u32',
            zerothDelayTrancheWidth: 'u32',
            relayVrfModuloSamples: 'u32',
            nDelayTranches: 'u32',
            noShowSlots: 'u32',
            neededApprovals: 'u32'
        },
        OldV1SessionInfo: {
            validators: 'Vec<ValidatorId>',
            discoveryKeys: 'Vec<AuthorityDiscoveryId>',
            assignmentKeys: 'Vec<AssignmentId>',
            validatorGroups: 'Vec<Vec<ParaValidatorIndex>>',
            nCores: 'u32',
            zerothDelayTrancheWidth: 'u32',
            relayVrfModuloSamples: 'u32',
            nDelayTranches: 'u32',
            noShowSlots: 'u32',
            neededApprovals: 'u32'
        },
        SessionInfoValidatorGroup: 'Vec<ParaValidatorIndex>',
        SignedAvailabilityBitfield: {
            payload: 'BitVec',
            validatorIndex: 'ParaValidatorIndex',
            signature: 'ValidatorSignature'
        },
        SignedAvailabilityBitfields: 'Vec<SignedAvailabilityBitfield>',
        SigningContext: {
            sessionIndex: 'SessionIndex',
            parentHash: 'Hash'
        },
        SlashingOffenceKind: {
            _enum: ['ForInvalid', 'AgainstValid']
        },
        Statement: {
            _enum: {
                Never: 'Null', // starts at 1
                Candidate: 'Hash',
                Valid: 'Hash',
                Invalid: 'Hash'
            }
        },
        TransientValidationData: {
            maxCodeSize: 'u32',
            maxHeadDataSize: 'u32',
            balance: 'Balance',
            codeUpgradeAllowed: 'Option<BlockNumber>',
            dmqLength: 'u32'
        },
        UpgradeGoAhead: {
            _enum: ['Abort', 'GoAhead']
        },
        UpgradeRestriction: {
            _enum: ['Present']
        },
        UpwardMessage: 'Bytes',
        ValidationFunctionParams: {
            maxCodeSize: 'u32',
            relayChainHeight: 'RelayChainBlockNumber',
            codeUpgradeAllowed: 'Option<RelayChainBlockNumber>'
        },
        ValidationCode: 'Bytes',
        ValidationCodeHash: 'Hash',
        ValidationData: {
            persisted: 'PersistedValidationData',
            transient: 'TransientValidationData'
        },
        ValidationDataType: {
            validationData: 'ValidationData',
            relayChainState: 'Vec<Bytes>'
        },
        ValidatorSignature: 'Signature',
        ValidityAttestation: {
            _enum: {
                Never: 'Null', // starts at 1
                Implicit: 'ValidatorSignature',
                Explicit: 'ValidatorSignature'
            }
        },
        MessagingStateSnapshot: {
            relayDispatchQueueSize: '(u32, u32)',
            egressChannels: 'Vec<MessagingStateSnapshotEgressEntry>'
        },
        MessagingStateSnapshotEgressEntry: '(ParaId, AbridgedHrmpChannel)',
        SystemInherentData: 'ParachainInherentData',
        VecInboundHrmpMessage: 'Vec<InboundHrmpMessage>'
    }
};
